import { useCallback, useMemo } from 'react';
import { InputType } from '../../enums';
import { AdditionalInvestmentData, AdditionalInvestmentInputData, GovernmentFundingCorrectionParams, GovernmentFundingData, ImageDropdownItem } from '../../models';
import { useAppDispatch, useAppSelector } from '../common';
import { getSystemActions, priceExchanger } from '../../services';
import { ApplicationType, SystemType } from '../../types';

export interface CommercialViewParameters {
    systemType: SystemType
    applicationType: ApplicationType
}

export const useCommercialView = ({ systemType, applicationType }: CommercialViewParameters) => {
    const dispatch = useAppDispatch();
    const { regionSettings, defaultCurrency } = useAppSelector(x => x.regionSettings);
    const { referenceSystem, alternativeSystem } = useAppSelector(state => applicationType == 'pump' ? state.pumpSystem : state.fanSystem);
    const { referenceSystemCommercialData, alternativeSystemCommercialData } = useAppSelector(state => applicationType == 'pump' ? state.pumpSystemsCommercialData : state.fanSystemsCommercialData);
    const { systemInvestmentCostHandler, commercialDataHandler, additionalInvestmentHandler, governmentFundingDataHandler } = getSystemActions(applicationType, systemType);
    const isReferenceSystem = systemType == 'reference';

    const calculateAdditionalInvestments = (investmentInputData: AdditionalInvestmentInputData[]): number => {
        return investmentInputData.filter(item => !isNaN(Number(item.value))).reduce((sum, current) => sum + Number(current.value), 0);
    };

    const systemState = useMemo(() => isReferenceSystem
        ? referenceSystem
        : alternativeSystem,
        [alternativeSystem, isReferenceSystem, referenceSystem]);

    const commercialData = useMemo(() => isReferenceSystem
        ? referenceSystemCommercialData
        : alternativeSystemCommercialData,
        [alternativeSystemCommercialData, isReferenceSystem, referenceSystemCommercialData]);

    const keepGrantSumAtOrBelowMaxLimit = useCallback((newPriceParams: Partial<GovernmentFundingCorrectionParams>) => {
        const priceParams: GovernmentFundingCorrectionParams = {
            motorCustomerPrice: commercialData.motorPrices.customerPrice,
            motorExpenditure: commercialData.motorPrices.expenditureForRepairs,
            controlUnitCustomerPrice: commercialData.controlUnitPrices.customerPrice,
            controlUnitExpenditure: commercialData.controlUnitPrices.expenditureForRepairs,
            investmenInput: commercialData.additionalInvestments.additionalInvestmentItems,
            ...newPriceParams
        };

        const newInvestments = Number(priceParams.motorCustomerPrice) + Number(priceParams.motorExpenditure)
            + Number(priceParams.controlUnitCustomerPrice) + Number(priceParams.controlUnitExpenditure) + calculateAdditionalInvestments(priceParams.investmenInput);
        if (Number(commercialData.governmentFunding.grantSum) > newInvestments) {
            dispatch(governmentFundingDataHandler({
                grantRate: '100',
                grantSum: newInvestments?.toString() ?? '0'
            }));
        }


    }, [commercialData.governmentFunding.grantSum, dispatch, governmentFundingDataHandler, commercialData.additionalInvestments, commercialData.motorPrices, commercialData.controlUnitPrices]);

    const controlMode = useMemo(() =>
        systemState?.controlModeComponent?.inputs?.find(x => x.inputType == InputType.ImageDropdown) as ImageDropdownItem,
        [systemState?.controlModeComponent]);

    const motor = useMemo(() =>
        systemState?.motorComponent.inputs.find(x => x.inputType == InputType.ImageDropdown) as ImageDropdownItem,
        [systemState?.motorComponent.inputs]);

    const controlComponent = useMemo(() =>
        systemState?.controlUnitComponent.inputs.find(x => x.inputType == InputType.ImageDropdown) as ImageDropdownItem,
        [systemState?.controlUnitComponent.inputs]);

    const additionalInevestments = useMemo(() =>
        calculateAdditionalInvestments(commercialData.additionalInvestments.additionalInvestmentItems),
        [commercialData.additionalInvestments.additionalInvestmentItems]);

    const exchangedAdditionalInvestments = priceExchanger(additionalInevestments, defaultCurrency, regionSettings?.currency);

    const exchangedMotorListPrice = useMemo(() =>
        priceExchanger(systemState?.motorComponent.selectedMotor.listPrice?.listPrice, systemState?.motorComponent.selectedMotor.listPrice?.currency, regionSettings?.currency),
        [regionSettings?.currency, systemState?.motorComponent.selectedMotor.listPrice]);

    const exchangedControlComponentListPrice = useMemo(() =>
        priceExchanger(systemState?.controlUnitComponent.selectedControlUnit.listPrice?.listPrice, systemState?.controlUnitComponent.selectedControlUnit.listPrice?.currency, regionSettings?.currency),
        [regionSettings?.currency, systemState?.controlUnitComponent.selectedControlUnit.listPrice]);

    const exchangedMotorCustomerPrice = useMemo(() =>
        priceExchanger(Number(commercialData.motorPrices.customerPrice) ?? 0, defaultCurrency, regionSettings?.currency),
        [commercialData.motorPrices.customerPrice, defaultCurrency, regionSettings?.currency]);

    const exchangedControlComponentCustomerPrice = useMemo(() =>
        priceExchanger(Number(commercialData.controlUnitPrices.customerPrice) ?? 0, defaultCurrency, regionSettings?.currency),
        [commercialData.controlUnitPrices.customerPrice, defaultCurrency, regionSettings?.currency]);

    const exchangedMotorExpenditureForRepairs = useMemo(() =>
        priceExchanger(Number(commercialData.motorPrices.expenditureForRepairs) ?? 0, defaultCurrency, regionSettings?.currency),
        [commercialData.motorPrices.expenditureForRepairs, defaultCurrency, regionSettings?.currency]);

    const exchangedControlComponentExpenditureForRepairs = useMemo(() =>
        priceExchanger(Number(commercialData.controlUnitPrices.expenditureForRepairs) ?? 0, defaultCurrency, regionSettings?.currency),
        [commercialData.controlUnitPrices.expenditureForRepairs, defaultCurrency, regionSettings?.currency]);

    const totalInvestment = useMemo(() => additionalInevestments
        + Number(commercialData.motorPrices.customerPrice) + Number(commercialData.motorPrices.expenditureForRepairs)
        + Number(commercialData.controlUnitPrices.customerPrice) + Number(commercialData.controlUnitPrices.expenditureForRepairs),
        [additionalInevestments, commercialData.controlUnitPrices.customerPrice, commercialData.controlUnitPrices.expenditureForRepairs, commercialData.motorPrices.customerPrice, commercialData.motorPrices.expenditureForRepairs]);

    const exchangedTotalInvestment = priceExchanger(totalInvestment, defaultCurrency, regionSettings?.currency);

    const handleNettoInvestmentChange = useCallback((value: number) => dispatch(systemInvestmentCostHandler(value)), [dispatch, systemInvestmentCostHandler]);

    const handleMotorCustomerPriceChange = useCallback((value: string) => {
        const newCustomerPrice = priceExchanger(Number(value) ?? 0, regionSettings?.currency, defaultCurrency)?.toString() ?? '0';
        keepGrantSumAtOrBelowMaxLimit({ motorCustomerPrice: newCustomerPrice });

        dispatch(commercialDataHandler({
            motorPrices: {
                ...commercialData.motorPrices,
                discount: ((Number(systemState?.motorComponent.selectedMotor.listPrice?.listPrice) - Number(value))
                    / Number(systemState?.motorComponent.selectedMotor.listPrice?.listPrice)
                    * 100).toString(),
                customerPrice: newCustomerPrice
                
            }
        }));
    }, [dispatch, commercialDataHandler, systemState?.motorComponent.selectedMotor.listPrice?.listPrice, regionSettings?.currency, defaultCurrency, commercialData.motorPrices, keepGrantSumAtOrBelowMaxLimit]);

    const handleMotorDiscountChange = useCallback((value: string) => {
        const newCustomerPrice = (Number(systemState?.motorComponent.selectedMotor.listPrice?.listPrice) * (1 - Number(value) / 100)).toString();
        keepGrantSumAtOrBelowMaxLimit({ motorCustomerPrice: newCustomerPrice });

        dispatch(commercialDataHandler({
            motorPrices: {
                ...commercialData.motorPrices,
                customerPrice: newCustomerPrice,
                discount: value
            }
        }));
    }, [dispatch, commercialDataHandler, systemState?.motorComponent.selectedMotor.listPrice?.listPrice, commercialData.motorPrices, keepGrantSumAtOrBelowMaxLimit]);

    const handleMotorExpenditureForRepairsChange = useCallback((value: string) => {
        const newExpenditure = priceExchanger(Number(value) ?? 0, regionSettings?.currency, defaultCurrency)?.toString() ?? '0';
        keepGrantSumAtOrBelowMaxLimit({ motorExpenditure: newExpenditure });


        dispatch(commercialDataHandler({
            motorPrices: {
                ...commercialData.motorPrices,
                expenditureForRepairs: newExpenditure
            }
        }));
    }, [commercialDataHandler, defaultCurrency, dispatch, regionSettings?.currency, commercialData.motorPrices, keepGrantSumAtOrBelowMaxLimit]);

    const handleControlcomponentCustomerPriceChange = useCallback((value: string) => {
        const newCustomerPrice = priceExchanger(Number(value) ?? 0, regionSettings?.currency, defaultCurrency)?.toString() ?? '0';
        keepGrantSumAtOrBelowMaxLimit({ controlUnitCustomerPrice: newCustomerPrice });

        dispatch(commercialDataHandler({
            controlUnitPrices: {
                ...commercialData.controlUnitPrices,
                discount: ((Number(systemState?.controlUnitComponent.selectedControlUnit.listPrice?.listPrice) - Number(value))
                    / Number(systemState?.controlUnitComponent.selectedControlUnit.listPrice?.listPrice)
                    * 100).toString(),
                customerPrice: newCustomerPrice

            }
        }));
    }, [dispatch, commercialDataHandler, systemState?.controlUnitComponent.selectedControlUnit.listPrice?.listPrice, regionSettings?.currency, defaultCurrency, commercialData.controlUnitPrices, keepGrantSumAtOrBelowMaxLimit]);

    const handleControlComponentDiscountChange = useCallback((value: string) => {
        const newCustomerPrice = (Number(systemState?.controlUnitComponent.selectedControlUnit.listPrice?.listPrice) * (1 - Number(value) / 100)).toString();
        keepGrantSumAtOrBelowMaxLimit({ controlUnitCustomerPrice: newCustomerPrice });


        dispatch(commercialDataHandler({
            controlUnitPrices: {
                ...commercialData.controlUnitPrices,
                customerPrice: newCustomerPrice,
                discount: value
            }
        }));
    }, [dispatch, commercialDataHandler, systemState?.controlUnitComponent.selectedControlUnit.listPrice?.listPrice, commercialData.controlUnitPrices, keepGrantSumAtOrBelowMaxLimit]);

    const handleControlComponentExpenditureForRepairsChange = useCallback((value: string) => {
        const newExpenditure = priceExchanger(Number(value) ?? 0, regionSettings?.currency, defaultCurrency)?.toString() ?? '0';
        keepGrantSumAtOrBelowMaxLimit({ controlUnitExpenditure: newExpenditure });
        dispatch(commercialDataHandler({
            controlUnitPrices: {
                ...commercialData.controlUnitPrices,
                expenditureForRepairs: newExpenditure
            }
        }));

    }, [commercialDataHandler, defaultCurrency, dispatch, regionSettings?.currency, commercialData.controlUnitPrices, keepGrantSumAtOrBelowMaxLimit]);

    const handleSystemGovernmentFundingDataChange = useCallback((value: Partial<GovernmentFundingData>) => {
        dispatch(governmentFundingDataHandler(value));
    }, [dispatch, governmentFundingDataHandler]);

    const handleAdditionalInvestmentDataChange = useCallback((value: Partial<AdditionalInvestmentData>) => {
        keepGrantSumAtOrBelowMaxLimit({ investmenInput: value.additionalInvestmentItems ?? [] });

        dispatch(additionalInvestmentHandler(value));
    }, [additionalInvestmentHandler, dispatch, keepGrantSumAtOrBelowMaxLimit]);

    return {
        commercialData,
        controlComponent,
        controlMode,
        exchangedAdditionalInvestments,
        exchangedControlComponentCustomerPrice,
        exchangedControlComponentListPrice,
        exchangedMotorCustomerPrice,
        exchangedMotorListPrice,
        exchangedTotalInvestment,
        exchangedMotorExpenditureForRepairs,
        exchangedControlComponentExpenditureForRepairs,
        handleAdditionalInvestmentDataChange,
        handleControlcomponentCustomerPriceChange,
        handleControlComponentDiscountChange,
        handleControlComponentExpenditureForRepairsChange,
        handleMotorCustomerPriceChange,
        handleMotorDiscountChange,
        handleMotorExpenditureForRepairsChange,
        handleNettoInvestmentChange,
        handleSystemGovernmentFundingDataChange,
        motor,
        systemState,
        totalInvestment
    };
};
