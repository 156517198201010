import { CookieType, Language } from '../enums';
import { CookieService } from './CookieService';

declare global {
    interface Window {
        ste_statistic: any[]
    }
}

export class TrackingService {
    static InitializeStatistics = () => {
        window.ste_statistic = window.ste_statistic || [];
    };
    
    static TrackPage (path: string, language: Language) {
        if (CookieService.isCookieAllowed(CookieType.Performance)){
            TrackingService.InitializeStatistics();
            window.ste_statistic.push({
                action: 'page.ready',
                data: {
                    page: {
                        path: path,
                        country: 'WW',
                        language: language
                    }
                }
            });
        }
    }
}
