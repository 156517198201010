import React, { useCallback, useMemo } from 'react';
import { TRANSLATIONS } from '../../../constants';
import { useCurrency, useHook } from '../../../hooks';
import { useTranslate } from '../../../hooks/common';
import { AdditionalInvestmentData } from '../../../models';
import AdditionalInvestmentInput from './AdditionalInvestmentInput';
import Box from '../../common/Box';
import Button, { ButtonType } from '../../common/Button';
import Flex from '../../common/Flex';
import Icon, { IconType } from '../../common/Icon';
import IconButton from '../../common/IconButton';
import Modal from '../../common/Modal';
import Text from '../../common/Text';
import TextInput from '../../common/TextInput';
import CommercialViewAdditionalInvetmentInfo from './ModalInfo/CommercialViewAdditionalInvestmentInfo';

export interface AdditionalInvestmentProps {
    color: 'reference' | 'alternative';
    dark?: boolean;
    additionalInvestmentData: AdditionalInvestmentData;
    exchangedControlComponentCustomerPrice?: string;
    exchangedAdditionalInvestmentSum: number;
    exchangedMotorExpenditureForRepairs?: number;
    exchangedControlComponentExpenditureForRepairs?: number;
    exchangedMotorCustomerPrice?: string;
    onAdditionalInvestmentDataChange: (value: Partial<AdditionalInvestmentData>) => void;
}

const AdditionalInvestment = ({
    color,
    additionalInvestmentData,
    dark,
    exchangedAdditionalInvestmentSum,
    exchangedMotorCustomerPrice,
    exchangedControlComponentCustomerPrice,
    exchangedControlComponentExpenditureForRepairs,
    exchangedMotorExpenditureForRepairs,
    onAdditionalInvestmentDataChange
}: AdditionalInvestmentProps) => {
    const { translate } = useTranslate();

    const exchangedTotalInvestmentSum = useMemo(
        () => exchangedAdditionalInvestmentSum
            + Number(exchangedMotorCustomerPrice)
            + (exchangedMotorExpenditureForRepairs ?? 0)
            + Number(exchangedControlComponentCustomerPrice)
            + (exchangedControlComponentExpenditureForRepairs ?? 0),
        [
            exchangedAdditionalInvestmentSum,
            exchangedMotorCustomerPrice,
            exchangedMotorExpenditureForRepairs,
            exchangedControlComponentCustomerPrice,
            exchangedControlComponentExpenditureForRepairs
        ]
    );

    const {
        closeAdditionalInvestment,
        handleCreateItem,
        handleDeleteItemByIndex,
        handleEnableEditClick,
        handleIsModalOpenChange,
        handleUpdateItemLabel,
        handleUpdateItemValue,
        exchangedAdditionalInvestmentItems
    } = useHook(x => x.additionalInvestments({
        onAdditionalInvestmentDataChange,
        additionalInvestmentItems: additionalInvestmentData.additionalInvestmentItems
    }));

    const handleOpenModalClick = useCallback(() => handleIsModalOpenChange(true), [handleIsModalOpenChange]);
    const handleCloseModalClick = useCallback(() => handleIsModalOpenChange(false), [handleIsModalOpenChange]);
    const { currencyFormat } = useCurrency();

    return (
        <>
            {!additionalInvestmentData.enableEdit && (
                <Flex direction='column'>
                    <Button
                        dark={dark}
                        style={{ padding: '10px 16px' }}
                        roundedBorder
                        type={ButtonType.Secondary}
                        onClick={handleEnableEditClick}
                    >
                        <Icon type={IconType.AddWorkorder} />
                        {translate(TRANSLATIONS.generated['System.AdditionalInvestment'])}
                    </Button>
                </Flex>
            )}
            {additionalInvestmentData.enableEdit && (
                <Box edgeColor={color} dark={dark} style={{ padding: 16 }}>
                    <Flex direction='column' alignment='stretch' justification='space-between'>
                        <div>
                            <Flex alignment='start' justification='space-between' gap={16} style={{ paddingBottom: 8 }}>
                                <Text color={color} dark={dark} type='subtitle-small'>
                                    {translate(TRANSLATIONS.generated['System.AdditionalInvestment'])}
                                </Text>
                                <Flex gap={8}>
                                    <IconButton dark={dark} iconType={IconType.Information} size={16} layer={2} onClick={handleOpenModalClick} />
                                    <IconButton dark={dark} iconType={IconType.Close} size={24} layer={2} onClick={closeAdditionalInvestment} />
                                </Flex>
                            </Flex>
                            {exchangedAdditionalInvestmentItems.map((e, i) => (
                                <AdditionalInvestmentInput
                                    numberFormatOptions={currencyFormat}
                                    key={i}
                                    index={i}
                                    style={{ paddingTop: 16 }}
                                    inputData={e}
                                    showDeleteButton={additionalInvestmentData.additionalInvestmentItems.length !== 1}
                                    dark={dark}
                                    onUpdateItemLabel={handleUpdateItemLabel}
                                    onUpdateItemValue={handleUpdateItemValue}
                                    onDeleteItem={handleDeleteItemByIndex}
                                />
                            ))}
                            {additionalInvestmentData.additionalInvestmentItems.length < 3 && (
                                <div style={{ paddingTop: 16 }}>
                                    <Button
                                        dark={dark}
                                        roundedBorder
                                        style={{ padding: '10px 16px' }}
                                        type={ButtonType.Secondary}
                                        onClick={handleCreateItem}
                                    >
                                        <Icon type={IconType.AddWorkorder} />
                                        {translate(TRANSLATIONS.generated['Common.AddNew'])}
                                    </Button>
                                </div>
                            )}
                        </div>
                        <div>
                            <TextInput
                                style={{ paddingTop: 24 }}
                                label={translate(TRANSLATIONS.generated['System.SumAdditionalInvestment'])}
                                readOnly
                                numberFormatOptions={currencyFormat}
                                withFullBorder
                                dark={dark}
                                value={exchangedAdditionalInvestmentSum.toString()}
                                name='sumAdditionalInvestment'
                            />
                            <TextInput
                                style={{ paddingTop: 16 }}
                                label={translate(TRANSLATIONS.generated['System.TotalInvestment'])}
                                readOnly
                                numberFormatOptions={currencyFormat}
                                withFullBorder
                                dark={dark}
                                value={exchangedTotalInvestmentSum.toString()}
                                name='totalInvestment'
                            />
                        </div>
                    </Flex>
                    <Modal
                        open={additionalInvestmentData.isModalOpen}
                        allowClickAway
                        movable
                        onClose={handleCloseModalClick}
                        dark={dark}
                        key={'info-modal'}
                    >
                        <CommercialViewAdditionalInvetmentInfo />
                    </Modal>
                </Box>
            )}
        </>
    );
};

export default AdditionalInvestment;
