import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { DEFAULT_CURRENCY_CODE } from '../constants';
import { Currency, ServiceResult, SystemState } from '../models';

export const apiCall = async <T extends ServiceResult>(responsePromise: Promise<T>, onSuccess: (reponse: T) => Promise<void>, onError: (response?: T) => Promise<void>) => {
    let response: T = null as never;

    try {
        response = await responsePromise;
    } catch { }

    if (response && !response.hasError) {
        await onSuccess(response);
    } else {
        await onError(response);
    }
};

export const apiCalls = async <T extends ServiceResult>(responsePromises: Promise<T[]>, onSuccess: (reponses: T[]) => Promise<void>, onError: () => Promise<void>) => {
    let responses: T[] = [];

    try {
        responses = await responsePromises;
    } catch { }

    if (responses && responses.every(x => x && !x.hasError)) {
        await onSuccess(responses);
    } else {
        await onError();
    }
};

export const toggleListElement = <T, >(list: T[], element: T) => list.includes(element) ? list.filter(x => x !== element) : [...list, element];

export const priceExchanger = (priceToExchange?: number, startCurrency?: Currency, targetCurrency?: Currency) => {
    let exchangedPrice = 0;

    if (priceToExchange === undefined || priceToExchange === null) {
        return undefined;
    }

    if (!startCurrency || !targetCurrency) {
        return exchangedPrice;
    }

    exchangedPrice = priceToExchange;

    if (startCurrency.id === targetCurrency.id) {
        return exchangedPrice;
    }

    exchangedPrice /= startCurrency.rateToEuro ?? 1;

    if (targetCurrency.code === DEFAULT_CURRENCY_CODE) {
        return exchangedPrice;
    }

    exchangedPrice *= targetCurrency.rateToEuro ?? 1;

    return exchangedPrice;
};

export const getActionCreatorForCondition = <P>(
    condition: boolean,
    actionCreatorIfTrue: ActionCreatorWithPayload<P, string>,
    actionCreatorIfFalse: ActionCreatorWithPayload<P, string>
) => condition ? actionCreatorIfTrue : actionCreatorIfFalse;

export const getMepsWarning = (systemType: string | undefined, systemState: SystemState) => {
    return systemType == 'reference' ? systemState.referenceHasMepsWarning : systemState.alternativeHasMepsWarning;
};
