import React, { useContext, useEffect } from 'react';
import { TrackingService } from '../../../../services/TrackingService';
import { useAppSelector } from '../../../../hooks/common';
import { PUMP_SYSTEMS_SUB_PAGES } from '../../../../constants';
import { PumpSystemsContext } from '../PumpSystems';
import CommercialViewCard from '../../CommercialView/CommercialViewCard';

const PumpSystemsCommercialView = () => {
    const language = useAppSelector(state => state.translation.language);
    const { onSubPageChange } = useContext(PumpSystemsContext);

    useEffect(() => {
        TrackingService.TrackPage(window.location.pathname, language);
    }, []);
    
    useEffect(() => {
        onSubPageChange && onSubPageChange(PUMP_SYSTEMS_SUB_PAGES.indexOf('commercial'));
    }, [onSubPageChange]);

    return (
        <div className='systems-comparison-grid commercial-view'>
            <CommercialViewCard systemType='reference' applicationType='pump' />
            <CommercialViewCard systemType='alternative' applicationType='pump' />
        </div>
    );
};

export default PumpSystemsCommercialView;
